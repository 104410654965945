<template>
<v-app><v-main class="loginbg"><v-container class="fill-height" fluid><v-row align="center" justify="center"><v-col cols="12" sm="8" md="4"><v-form v-model="valid" ref="form"><v-card elevation="13" :loading="ajaxProcess">
    <v-toolbar color="success" dark flat><v-toolbar-title>{{ $t('Please Login') }}</v-toolbar-title></v-toolbar>
    <v-card-text>
            <v-text-field :label="$t('Member Num.')" prepend-icon="fa-user" type="text" autocomplete="new-user" required  :rules="rules.isRequired" v-model="noanggota" @keyup.enter="movetopassword"/>           
            <v-text-field label="Password" prepend-icon="fa-key" autocomplete="new-password" :rules="rules.isRequired" @keyup.enter="doLogin"
                       ref="passwordText" v-model="pass" :append-icon="iconpassword" @click:append="passwordeye" :type="typepasswordnya" ></v-text-field>
    </v-card-text>
    <v-card-text v-show="errorMessage.length > 0">
        <v-alert type="error" outlined>{{ errorMessage }}</v-alert>
    </v-card-text>
    <v-card-actions class="justify-center">
        <v-btn elevation="2" largex-large block class="success" :loading="ajaxProcess" @click="doLogin">{{ $t('Login') }}</v-btn>
    </v-card-actions>
    <v-card-text align="center" justify="center">
        {{ $t('Not registered yet') }} <a href="register">{{ $t('Register') }}</a>
     </v-card-text>
</v-card></v-form></v-col></v-row></v-container></v-main></v-app>
</template>
<script>
const sha1 = require('sha1');
export default {
  data: vm => ({
    noanggota: '',
    pass:'',typepasswordnya:'password',iconpassword:'fa-eye-slash',
    valid: false,ajaxProcess:false,errorMessage:'',
    rules: {isRequired: [
      v => !!v || vm.$t('Is Required')
    ]}
  }),
  methods:{
    movetopassword() {
        this.$refs.passwordText.focus();
    },
    passwordeye() {
		this.typepasswordnya = (this.typepasswordnya == 'password' ? 'text' : 'password');
		this.iconpassword = (this.iconpassword == 'fa-eye-slash' ? 'fa-eye' : 'fa-eye-slash');
	},
    doLogin() {
        var vue = this;
        vue.$refs.form.validate();
        if (vue.ajaxProcess || !vue.valid) {
            return;
        }
        vue.ajaxProcess = true;
        vue.errorMessage = '';
        var parameters = {noanggota: vue.noanggota, pass:sha1(vue.pass)};
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(parameters)
        };
        
        
        fetch(process.env.VUE_APP_WEB_API + 'api/cek-login', requestOptions)
        .then(async response => {
            const data = await response.json();
            var message = vue.$t(data.message);
            if (data.success) {
                window.location.href = '/';
            } else {
                vue.errorMessage = vue.$t(message);
                vue.ajaxProcess = false;
            }
        })
        .catch(error => {
            vue.ajaxProcess = false;
            alert('Refresh this page' + error);
        });
    }
  },
  watch: {
    errorMessage(val) {
        if (val.length > 0) {
            setTimeout(() => { this.errorMessage = '';  }, 7000);
        }
      },
  }
}
</script>
